import * as React from "react"
import { graphql } from 'gatsby'

import Seo from "../components/seo"
import AnimationWrapper from "../components/animationWrapper"
import Carousel from "../components/carousel"
import ContactSection from "../components/contactSection"
import Container from "../components/container"
import Header from "../components/header"
import TextSection from "../components/textSection"
import Layout from "../components/layout"

import "./o-firmie.css"

const VirtualTourPage = ({data, location}) => {

  // set meta description values
  const metaDescription = data.page.frontmatter.meta_description || `Czamaninek ➤ ${data.page.frontmatter.title} ✔️ Ekologiczne materiały budowlane ✔️ Polski producent ⭐ Sprawdź!`;

  return (
    <Layout location={location}>

      <Seo title={data.page.frontmatter.title} description={metaDescription} />

      <AnimationWrapper>
        <Header data={data.page.frontmatter.header} slug={data.page.frontmatter.slug} />
      </AnimationWrapper>

      {data.page.frontmatter.text_section_main.active &&
        <Container>
          <AnimationWrapper>
            <TextSection className="description-medium" data={data.page.frontmatter.text_section_main} />
          </AnimationWrapper>
        </Container>
      }

      <AnimationWrapper className="youtube-wrapper">
        <iframe
          width="100%"
          height="100%"
          src="https://images.czamaninek.pl/virtual-tour/1/index.html"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen>
        </iframe>
      </AnimationWrapper>

      <section id="realizacje-carousel-section" className="page-section">
        <Carousel data={data.realizacjeCarousel.frontmatter.carousel_gallery} location="realizacje" />
      </section>

      <section id="contact-data" className="page-section">
        <ContactSection data={data.contactSection.frontmatter.contact_section} />
      </section>

    </Layout>
  )
}

export const pageQuery = graphql`
  query VirtualTourPage{
    page: mdx(frontmatter: { slug: { eq: "wirtualny-showroom" }, type: { eq: "page" } }) {
      frontmatter{
        title
        slug
        header{
          title
          breadcrumbs {
            title
            url
          }
          overlay
          image {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
        }
        text_section_main{
          active
          title
          title_visible
          # icon{
          #   childImageSharp {
          #     gatsbyImageData(
          #       transformOptions: {fit: COVER},
          #       webpOptions: {quality: 95}
          #     )
          #   }
          # }
          align
          text
          button_text
          button_url
          button_style
        }
      }
    }
    realizacjeCarousel: mdx(
      frontmatter: {slug: {eq: "realizacje-carousel"}, type: {eq: "global"}}
    ){
      frontmatter{
        carousel_gallery{
          text_section{
            title
            title_visible
            subtitle
            text
            button_text
            button_url
            button_style
          }
          carousel_settings{
            slides_per_view
            transition_speed
            transition_delay
            object_fit
            rotate
            stretch
            depth
            scale
            slide_shadows
          }
          images{
            title
            title_visible
            description
            status
            image{
              childImageSharp{
                gatsbyImageData(
                  webpOptions: {quality: 95}
                )
              }
            }
          }
        }
      }
    }
    contactSection: mdx(
      frontmatter: {slug: {eq: "contact-section"}, type: {eq: "global"}}
    ){
      frontmatter{
        contact_section{
          title
          description
          type
          fields
          submit_button_style
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
          image_object_fit
          image_object_position
        }
      }
    }
  }
`

export default VirtualTourPage
